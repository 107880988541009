.login{
    height: 100%;
    display: flex;
}
.login .login-left{
    width: 834px;
    height: 100%;
    background: url("@assets/images/login/login-bg2.png") no-repeat;
}
.login .login-right{
    width: 100%;
    height: 100%;
    background: rgb(242,243,252);
    position: relative;
}
.login .login-bg1{
    position: absolute;
    top: 0;
    left: 100px;
    width: 184px;
    height: 198px;
    background: url("@assets/images/login/login-bg1.png") no-repeat;
}
.login .login-bg2{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 335px;
    height: 308px;
    background: url("@assets/images/login/login-bg3.png") no-repeat 100% 100%;
}
.login .login-body{
    width: 575px;
    height: 414px;
    position: absolute;
    top: 100px;
    left: 380px;
    z-index: 99;
}
.login .login-line{
    width: 50px;
    height: 2px;
    background: #000;
}
.login h3{
    font-size: 46px;
    font-weight: 700;
    margin: 20px 0;
}
.login-body > div{
    font-size: 18px;
    margin: 10px 20px;
}
.login .login-inner{
    background: #fff;
    padding: 20px 0;
    border-radius: 30px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
.login .login-inner .ant-col-6{
    text-align: center;
    font-size: 14px;
}
.login .login-inner .ant-col-6 img{
    margin-bottom: 20px;
}
.login .login-inner .login-tips{
    padding: 0 25px;
    font-size: 14px;
}
.login .login-welcome{
    font-size: 86px;
    font-weight: 800;
    position: absolute;
    top: 433px;
    left: 423px;
    color: rgb(227,231,250);
    font-style: italic;
}