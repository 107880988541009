.header {
  width: 100%;
  height: 64px;
  line-height: 64px;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 3%);
  position: fixed;
  left: 0;
  top: 40px;
  z-index: 9;
  background-color: #fff;
}

.header .header-right {
  display: flex;
}
.header_tooltip .ant-tooltip-inner{
	padding: 0px 8px;
	font-size: 12px;
	min-height: 16px;
	max-height: 16px;
	line-height: 14px;
}

.header .header-left {
  width: 274px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header-left-dropdown {
  /* width: 80px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  height: 64px;
}

.header .header-left-dropdown b {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: inline-block;
  width: 100px;
}

.header .header-left-dropdown .anticon {
  font-size: 14px;
}

.header .header-left-dropdown .header-left-dropdown-daren {
  background: rgb(255, 230, 230);
  color: rgb(255, 102, 111);
  display: inline-block;
  border-radius: 8px;
  line-height: 14px;
}

.header .anticon {
  font-size: 20px;
}

.header .logo {
  display: inline-block;
}

.header .logo img {
  margin-top: -3px;
}

.header .header-nav,
.InletHeader .header-nav {
  display: flex;
}

.header .header-nav li,
.InletHeader .header-nav li {
  padding: 0 20px;
  cursor: pointer;
}

.header .header-nav .action {
  color: #6647ff;
}

.header+.ant-layout {
  margin: 124px auto 0;
}

.newsModal .ant-modal-header {
  background-color: #f3f4f6;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  color: #12141a;
  padding: 14px 24px;
}

.newsModal .ant-layout-sider {
  background: #fff;
  border-right: 1px solid #f0f0f0;
  padding: 20px 20px;
  text-align: center;
}

.newsModal .ant-layout-content {
  background: #fff;
  min-height: 532px;
  padding: 20px;
  max-height: 523px;
  position: relative;
}

.newsModal .ant-menu {
  height: 90%;
  border-right: 0;
}

.newsModal .ant-modal-body {
  padding: 0;
}

.newsModal .newsModal-red-tip {
  align-items: center;
  background: #f66;
  border-radius: 10px;
  color: #fff;
  display: flex;
  font: 12px/16px PingFangSC-Regular;
  height: 16px;
  justify-content: center;
  margin-left: 8px;
  min-width: 16px;
  padding: 0 4px;
}

.newsModal .ant-collapse-item {
  margin-bottom: 20px;
  border: 0;
  background-color: #f3f4f6;
  border-radius: 5px;
}

.newsModal .ant-collapse {
  background: #fff;
  border: 0;
}

.newsModal .ant-collapse-arrow {
  position: absolute;
  top: 17px;
  right: 5px;
}

.newsModal .ant-collapse-content-box {
  background-color: #f3f4f6;
  border-top: 0;
}

.newsModal .ant-collapse-content {
  border-top: 0;
}

.newsModal .newsModal-panel {
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
}

.newsModal .ant-collapse {
  min-height: 450px;
  max-height: 450px;
  overflow: auto;
  padding-right: 10px;
}

.newsModal .ant-pagination {
  text-align: right;
  position: absolute;
  right: 20px;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: 60px;
  line-height: 60px;
}

.customerService {
  width: 40px;
  position: fixed;
  bottom: 80px;
  right: 30px;
  background: #6647ff;
  border-radius: 3px;
  cursor: pointer;
}

.customerService img {
  width: 40px;
  height: 40px;
}

.customerService .customerService-text {
  display: none;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding-bottom: 10px;
}

.customerService .customerService-text span {
  display: inline;
  height: 18px;
}

.customerService:hover .customerService-text {
  display: flex;
}

.InletHeader {
  width: 100%;
  height: 58px;
  line-height: 58px;
  justify-content: space-between;
  padding: 0 50px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 3%);
  position: fixed;
  left: 0;
  top: 40px;
  z-index: 9;
  background-color: #141f33;
}

.InletHeader .header-inner {
  width: 1366px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: #dcdde0;
  font-size: 16px;
}

.InletHeader .logo {
  height: 30px;
  cursor: pointer;
  width: 244px;
  margin-top: 14px;
  display: inline-block;
}

.InletHeader .logo img {
  vertical-align: top;
  width: 84px;
}

.InletHeader a,
.InletHeader li {
  color: #dcdde0;
  font-size: 16px;
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Noto Sans CJK SC, Microsoft YaHei, 微软雅黑, sans-serif;
}

.InletHeader .header-right {
  display: flex;
}

.InletHeader .header-left {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InletHeader .header-nav {
  margin: 0;
  padding: 0;
}

.InletHeader .anticon,
.InletHeader .ant-badge {
  font-size: 16px;
  color: #dcdde0;
}

.InletHeader .header-left-dropdown {
  color: #dcdde0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .08);
  border-radius: 68px;
  box-sizing: border-box;
  height: 38px;
  max-width: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  font-size: 16px;
}
.InletHeader .header-left-dropdown .anticon{
  font-size: 14px;
  margin-left: 3px;
}
.InletHeader .header-left-dropdown .name{
  margin: 0 15px;
}
.InletHeader  .marketing .logo img {
  width: auto;
  height: 30px;
}
.InletHeader .marketing  .header-left {
  justify-content: flex-end;
  width: 200px;
}
.InletHeader .header-left-dropdown {
  background-color: transparent;
}