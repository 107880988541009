.scancode{
  height: 100%;
  background: #fff;
  margin-top: 40px;
}
.scancode .scancode-header{
  height: 73px;
  line-height: 73px;
  background: rgb(22,24,35);
  padding-left: 200px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 4%);
}
.scancode .scancode-header-logo img{
  margin-top: -3px;
  height: 70px;
}
.scancode .scancode-body{
  height: 353px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.scancode-body .scancode-body-right{
  text-align: center;
  padding-right: 30px;
}
.scancode-body .scancode-body-right div{
  line-height: 26px;
}
.scancode-body .scancode-body-right img{
  margin: 20px 0;
}
.scancode .ant-divider{
  height: 100%;
  margin: 0 50px;
}
.scancode .scancode-body-left p{
  color: #888;
}
.scancode .scancode-body-left .anticon-check{
  margin: 0 20px;
}
.scancode .ant-input{
  width: 283px;
}
.scancode .ant-form-item-control-input{
  width: 283px;
}
.scancode .ant-form{
  width: 300px;
}
.scancode .ant-checkbox-wrapper{
  font-size: 12px;
  white-space: nowrap;
}
.scancode .scancode-body-title{
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}
.scancode .ant-btn{
  background: #f85959;
  border-color: #f85959;
  width: 100%;
  margin: 10px 0 20px;
}
.scancode .verification-code{
  display: inline-block;
  width: 283px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #d9d9d9;
}
.scancode .verification-code div{
  display: initial;
  padding: 0 20px;
  border-left: 1px solid #d9d9d9;
}
.scancode .verification-code .ant-input{
  border: 0;
}