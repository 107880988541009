.commodityManagement{
  margin-left: 20px;
}
.commodityManagement .commodityManagement-header{
  background: #fff;
  padding: 20px;
}
.commodityManagement-header-title{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #edeef0;
  padding-bottom: 20px;
}
.commodityManagement-header-title div{
  font-size: 16px;
}
.commodityManagement-header-title span{
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.commodityManagement-header-title span:hover{
  color: #6647ff;
}
.displayWindow .commodityManagement-header-form{
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
}
.commodityManagement-header-form-right span{
  margin-right: 20px;
}
.commodityManagement-header-form .ant-select{
  margin-right: 20px;
}
.commodityManagement-table{
  background: #fff;
  margin-top: 20px;
  padding: 20px;
}
.commodityManagement .commodityManagement-table-header{
  display: flex;
  justify-content: space-between;
}
.commodityManagement-table-header strong {
  font-size: 16px;
  margin-right: 20px;
}
.commodityManagement-table-header-right span {
  font-size: 12px;
  color: #85878a;
}
.commodityManagement-table-header div:nth-child(2) button{
  margin-left: 10px;
  background: #fff;
}
.commodityManagement-table-header div:nth-child(2) .ant-btn-primary{
  background: #6647ff;
}
.commodityManagement .ant-table{
  margin-top: 20px;
}
.commodityManagement .ant-table-cell{
  background: #fff;
}
.commodityManagement-table .ant-btn-link{
  color: #6647ff;
}
.commodityManagement-table .ant-btn-link:hover{
  color: #6647ff;
}
.commodityManagement-table-name{
  display: flex;
}

.commodityManagement-table-name-center .ant-typography{
  margin-bottom: 0px;
}
.displayWindow .commodityManagement-table-name-center{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}
.displayWindow .commodityManagement-table-name-center div{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.commodityManagement-table-name-center div:nth-child(3){
  color: rgb(25, 102, 255);
  border: 1px solid rgba(25, 102, 255, 0.24);
  background-color: rgba(25, 102, 255, 0.06);
  margin: 0px 8px 0px 0px;
  width: 70px;
  font-size: 12px;
}
.addCommodity-table .commodityManagement-table-name-center div:nth-child(2){
  color: rgb(25, 102, 255);
  border: 1px solid rgba(25, 102, 255, 0.24);
  background-color: rgba(25, 102, 255, 0.06);
  margin: 0px 8px 0px 0px;
  width: 70px;
  font-size: 12px;
}
.commodityManagement-table-name-center div:nth-child(2){
  white-space: nowrap;
}
.displayWindow-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.displayWindow-header .displayWindow-header-lable{
  margin-right: 10px;
}
.displayWindow-header .ant-input{
  margin-right: 10px;
}
.addCommodity-table .addCommodity-footer{
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
}
.addCommodity-table .addCommodity-footer-right span{
  margin-right: 20px;
}
.addCommodity-footer-select{
  border-bottom: 1px solid rgb(241,242,243);
  padding: 10px 20px;
}
.addCommodity-footer-select + div{
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.EditCommodity-header{
  background-color: #fafafa;
  border-radius: 6px;
  display: flex;
  height: 120px;
  position: relative;
  width: 592px;
  overflow: hidden;
}
.EditCommodity-header img{
  margin-right: 20px;
}
.EditCommodity-header-body{
  color: #19191a;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 432px;

}
.EditCommodity-header-body div:nth-child(2){
  color: #707173;
  margin: 6px 0;
}
.EditCommodity-header-body .coupon-wrap{
  display: flex;
  justify-content: space-between;
}
.EditCommodity-header-body .coupon-wrap b{
  background-color: red;
  font-weight: normal;
  color: #fff;
  padding: 0 5px;
  border-radius: 3px;
  font-size: 12px;
}
.EditCommodity-body{
  margin-top: 20px;
}
.EditCommodity-body-item{
  width: 500px;
}
.EditCommodity-body-item .ant-input-affix-wrapper {
  margin: 10px 0;
}
.EditCommodity-body-item .example{
  margin-bottom: 10px;
  display: inline-block;
  color: #6647ff;
  cursor: pointer;
}
.example-info-shili{
  width: 180px;
  height: 322px;
  background-image: url("@assets/images/homeLive/display-window-shili.png");
  background-position: 100% 100%;
  background-size: 100%;
}
.example-info-shili2{
  width: 180px;
  height: 322px;
  background-image: url("@assets/images/homeLive/display-window-shili2.png");
  background-position: 100% 100%;
  background-size: 100%;
}
.EditCommodity-body-figure{
  width: 450px;
  display: flex;
  justify-content: start;
  padding: 0;
  margin-top: 20px;
}
.EditCommodity-body-figure li{
  position: relative;
  margin: 0 10px 10px 0 ;
}
.EditCommodity-body-figure li div{
  position: absolute;
  background: rgba(0,0,0,.5);
  border-radius: 4px;
  height: 92px;
  width: 92px;
  top: 0;
  left: 0;
}
.EditCommodity-body-figure li span{
  border-radius: 50%;
  display: block;
  height: 20px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 20px;
  background: #6647ff;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.addCommodity-distinguish .commodityManagement-table-name-center div:nth-child(2){
  color: rgb(25, 102, 255);
  border: 1px solid rgba(25, 102, 255, 0.24);
  background-color: rgba(25, 102, 255, 0.06);
  margin: 0px 8px 0px 0px;
  width: 70px;
  font-size: 12px;
}