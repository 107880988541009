.LiveSendCoupons{
  background: #fff;
  margin-left: 20px;
  padding: 20px;
}
.LiveSendCoupons .LiveSendCoupons-header{
  font-size: 20px;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
}
.LiveSendCoupons .LiveSendCoupons-header span{
  display: inline-block;
  font-size: 14px;
  color: rgb(188,188,187);
  background: rgb(243,244,246);
  padding: 0px 10px;
  margin-left: 20px;
  height: 32px;
  line-height: 32px;
}