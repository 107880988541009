.rechargeFundDrawer .red-sum{
  padding: 10px 20px;
  border-radius: 6px;
  background: #f9f9fa;
}
.rechargeFundDrawer .money-list{
  padding: 50px;
}
.rechargeFundDrawer .money-list .ant-radio-group .ant-col{
  padding: 20px 10px;
  border-radius: 6px;
  border: 1px solid #dcdde0;
  margin-bottom: 20px;
  margin-right: 2%;
}
.rechargeFundDrawer .money-list .ant-row.ant-form-item{
  align-items: center;
}
.rechargeFundDrawer .money-list .ant-form-item-label > label{
  width: 120px;
  text-align: center;
  font-size: 18px;
}
.rechargeFundDrawer .custom-money{
  width: 100%;
  border: 0;
  outline: none;
}