body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  list-style: none;
}
.dafen .ant-modal-body {
  padding: 0px !important;
}
.dafen .scoreTipsText {
  display: flex;
}

.dafen .scoreTipsText img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.dafen .expire-modal-btn {
  padding: 28px 26px 24px 26px;
  text-align: right;
}

.dafen .expire-modal-btn button {
  height: 34px;
  line-height: 0;
  border-radius: 4px;
  font-size: 14px;
}

.dafen .expire-modal-btn button.cancelBtn {
  background-color: #fff;
  border-color: #ccc;
  color: #999999;
  font-size: 14px;
}

.dafen .expire-modal-btn button.cancelBtn:hover {
  background-color: #f0f0f0 !important;
  border: 1px solid #ccc;
  color: #999 !important;
}

.dafen .expire-modal-btn button.cancelBtn+button:hover {
  background-color: #28bbac;
  border-color: #28bbac;
  opacity: .7;
  color: #fff;
}

.dafen .p-24 {
  padding-top: 30px;
  padding: 30px 26px 0 26px;
}

.dafen .ant-modal-content {
  border-radius: 4px;
}

.dafen .expire-modal-content-text .tipsTitle {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dafen .ant-modal-confirm-btns,
.dafen .ant-modal-confirm-body>.anticon {
  display: none;
}

.dafen .expire-modal-btn {
  padding: 30px 24px;
  text-align: center;
}

.dafen .expire-modal-btn button {
  min-width: 98px;
  padding: 4px 0;
  font-size: 14px;
  background-color: #28bbac;
  border-color: #28bbac;
  margin-bottom: 0;
  margin-left: 8px;
}

.dafen .expire-modal-btn button+button {
  margin-left: 16px;
}
.dafen .anticon-exclamation-circle{
  font-size: 20px !important;
  color: rgb(248,194,101);
  margin-right: 10px !important;
  margin-top: 2px;
}