.violationManagement{
  min-height: 600px;
  background-color: #fff;
  margin-left: 15px;
  padding: 30px;
}
.violationManagement .title{
  font-size: 18px;
  margin-bottom: 30px;
}
.violationManagement .contents{
  width: 170px;
  padding: 6px;
  background: #f3f4f6;
  border-radius: 6px;
  font-size: 14px;
}
.violationManagement .col-9f{
  color:#9b9d9f
}
.violationManagement ul{
  padding-left: 0;
}
.violationManagement ul li{
  list-style: disc;
}
.violationManagement table tbody td:nth-child(4){
  max-width: 220px;
}
.violationManagement .btn p,
.violationManagement .btn a{
  cursor: pointer;
  color: #6647ff;
}
.appeal .appeal-footer > div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.appeal .title-tips{
  background: #f3f4f6;
  padding: 10px 20px;
  border-radius: 6px;
}
.appeal textarea{
  border-radius: 6px;
}
.violation-modal{
  width: 620px !important;
}
.violation-modal .ant-modal-header{
  background: #f0f0f0;
}
.violation-modal .ant-modal-body{
  height: 516px;
}
.violation-modal .ant-modal-body>div{
  text-align: center;
}
.violation-modal .ant-modal-body>div,
.violation-modal video{
  height: 100%;
  text-align: center;
}
.violationManagement .radius_span{
  width: 6px;
  height: 6px;
  background-color: #ff3826;
  margin-right: 5px;
  border-radius: 50%;
}