@import "~antd/dist/antd.css";
#root{
  height: 100%;
}
.App{
  height: 100%;
  background: #f5f6fa;
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  overflow-x: auto;
}
.App::-webkit-scrollbar {
  width: 0px;
}
.App .app-main{
  width: 1336px;
  margin: 64px auto 0;
  background-color: #f5f6fa;
}
.App .intlet-main{
  width: 1336px;
  margin: 98px auto 0;
  background-color: #f5f6fa;
}
.ant-layout-sider{
  background-color: #f5f6fa;
}
.ant-layout-content{
  background-color: #f5f6fa;
  padding-bottom: 20px;
}
.a-input{
  border-radius: 6px;
}
.purposeTipsBox{
  width:100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#EEB95D; 
  color:#FFF;
  position: fixed;
  z-index: 99;
}
.purposeTipsBox img{
  width:16px;
  height:16px;
  margin-right: 3px;
}



