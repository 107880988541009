.InletPage {
  margin: 15px 0 15px 15px;
  width: 1088px;
}

.InletPage .slick-slide {
  border-radius: 3px;
  overflow: hidden;
  width: 1088px;
  height: 64px;
}

.InletPage .slick-slide img {
  width: 1088px;
  height: 64px;
}

.InletPage .ant-alert-info .ant-alert-icon {
  color: #1966ff;
}

.InletPage .ant-alert-info {
  background-color: rgba(25, 102, 255, .08);
  border: 1px solid rgba(25, 102, 255, .24);
}

.InletPage .ant-alert-info {
  background-color: rgba(25, 102, 255, .08);
  border: 1px solid rgba(25, 102, 255, .24);
  margin: 16px 0;
}

.InletPage .inner {
  display: flex;
  justify-content: space-between;
}

.InletPage .left {
  width: 768px;
}

.InletPage .right {
  width: 304px;
}