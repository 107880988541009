.home-live{
  margin-left: 20px;
  display: flex;
  height: 100%;
}
.home-live .live-console{
  width: 368px;
}
.live-console-open{
  background: rgba(0,0,0,0.9);
  border-radius: px;
  overflow: hidden;
  color: #fff;
  background-size: 100%;
  background-position: 100% 100%;
  position: relative;
  height: 460px;
}
.live-console-open-inner{
  padding: 20px;
  position: absolute;
  width: 100%;
  background: rgba(0,0,0,0.9);
}
.live-console-data-body .ant-col{
  margin-top: 10px;
}
.live-console-data-body .ant-col div{
  font-size: 18px;
}
.live-console-data-body .ant-col p{
  color: #999;
  font-size: 12px;
}
.live-console-comment-data{
  color: #555;
  height: 210px;
  overflow: auto;
}
.live-console-comment-data p{
  margin-bottom: 5px;
}

.home-live .live-commodity{
  width: 750px;
  height: 100%;
}
.home-live .live-console-open-title-right{
  display: flex;
}
p{
  margin: 0;
}
.home-live .live-console-open-title{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.home-live .live-console-open-title .anticon {
  font-size: 20px;
  color: rgb(134,129,127);
}
.home-live .live-console-open-title-right > div p:nth-child(2){
  background: rgb(75,66,63);
  border-radius: 5px;
  line-height: 19px;
  padding: 2px 2px;
}
.home-live .live-console-open-title-right > div p:nth-child(2) img{
  margin-bottom: 2px;
  margin-right: 2px;
}
.home-live .live-console-open-body{
  margin: auto 0;

}
.live-console-open-img{
  position: absolute;
  width: 100%;
  height: 100%;
}
.home-live .live-console-notstart{
  font-size: 12px;
  text-align: center;
  padding-top: 100px;
}
.home-live .live-console-notstart div{
  margin-bottom: 20px;
}
.home-live .live-console-notstart p{
  color: rgb(149,140,135);
  margin-bottom: 50px;
}
.home-live .live-console-notstart .anticon{
  font-size: 12px;
  color: rgb(149,140,135);
}
.home-live .live-console-shopping{
  background: rgb(252,245,242);
  color: rgb(216,126,73);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 36px;
  border: 1px solid rgb(249,236,230);
  margin: 10px 0;
  border-radius: 3px;
}
.home-live .live-console-shopping .ant-switch-checked{
  background: rgb(216,126,73);
}
.live-console-shopping-tip{
  padding-top: 10px;
}
.live-console-shopping-tip div{
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}
.live-console-shopping-tip p{
  padding-left: 10px;
}
.home-live .live-console-comment{
  height: 282px;
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
}
.home-live .live-console-comment h3{
  font-weight: 700;
}
.home-live .ant-empty{
  color: rgb(179,176,188);
}
.home-live .ant-empty-image img{
  width: 46px;
  height: 36px;
  margin-top: 70px;
}
.home-live .live-console-data h3{
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.home-live .live-console-data h3 span{
  cursor: pointer;
}
.home-live .live-console-data{
  min-height: 362px;
}
.home-live .live-console-data-tool{
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin-top: 20px;
}
.home-live .live-console-data-tool li{
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
.home-live .live-commodity{
  margin-left: 20px;
}
.home-live .live-commodity-title{
  display: flex;
  align-items: center;
  background: #fff;
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.home-live .live-commodity-title div button{
    margin-left: 20px;
    
}
.home-live .ant-btn-dangerous{
  border-color: #6647ff;
  color: #6647ff;
}
.home-live .live-commodity-title .ant-input{
  border: 0;
}
.home-live .live-commodity-title .ant-input-group-addon{
  background: #fff;
  border: 0;
}
.home-live .live-commodity-title .ant-input-group-wrapper{
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  overflow: hidden;
}
.home-live .live-commodity-title .ant-input-group-wrapper:hover{
  border-radius: 5px;
  border: 1px solid #6647ff;
  overflow: hidden;
}
.home-live .live-commodity-title .ant-input-search-button{
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 0;
  font-weight: normal;
  text-shadow: none;
  margin-left: 10px;
}

.home-live .live-commodity-title .ant-input-search-button:hover{
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 0;
  font-weight: normal;
  text-shadow: none;
}
.home-live .ant-input-affix-wrapper{
  border: 0;
}
.home-live .live-commodity-item{
  background: #fff;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
}
.home-live .live-commodity-item-close{
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgb(247,248,250);
  display: none;
}
.home-live .live-commodity-item:hover .live-commodity-item-close{
  display: block;
}
.home-live .live-commodity-item:hover{
  box-shadow: 0 4px 12px rgb(0 0 0 / 6%);
  transform: translateY(-1px);
  transition: transform .3s;
}
.home-live .live-commodity-item .live-commodity-item-center{
  display: flex;
}
.home-live .live-commodity-item-center > div{
  margin-left: 20px;
}
.home-live .live-commodity-item-center p{
  font-size: 12px;
  color: rgb(133,135,138);
}
.home-live .ant-typography{
  font-size: 12px;
  color: rgb(133,135,138);
}
.home-live .live-commodity-item-record{
  font-size: 12px;
  color: rgb(133,135,138);
}


.setSeckill-header{
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(249,236,230);
}
.setSeckill-header img{
  width: 38px;
  height: 38px;
  margin-right: 20px;
}
.setSeckill-header p{
  color: #999;
  font-size: 12px;
}
.setSeckill .ant-radio-button-wrapper{
  display: inline-block;
  width: 264px;
  height: 105px;
  margin-right: 10px;
  background: rgb(243,244,245);
}
.setSeckill .ant-radio-button-wrapper-checked{
  color: #000;
}
.setSeckill .ant-radio-button-wrapper-checked p{

  color: #999;
}
.setSeckill .ant-radio-button-wrapper:hover{
  color: #000;
}

.setSeckill .setSeckill-Radio{
  line-height: 16px;
  padding: 0 10px;
}
.setSeckill .setSeckill-Radio div{
  padding: 10px 0;
  font-size: 14px;
}
.setSeckill .setSeckill-Radio p{
  font-size: 12px;
  color: #999;
}
.setSeckill .ant-form{
  margin-top: 20px;
}
.setSeckill-table{
  background: rgb(251,251,251);
  padding: 20px;
}
.setSeckill-table .ant-table{
  margin-top: 20px;
}
.setSeckill-table .ant-table-cell{
  background: #fff;
}
.commoditySelect .ant-table-tbody > tr.ant-table-row-selected > td{
  background: #fff;
  border-color: #fff;
}
.commoditySelect .ant-table-tbody > tr.ant-table-row-selected:hover > td{
  background: #f0f0f0;
  border-color: #fff;
}
.setSeckill .ant-modal-header{
  background: #f3f4f6;
}
.addCommodity .ant-layout-content{
  background: #fff;
  padding: 20px;
}

.commoditySelect .commodity-lately-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commoditySelect .ant-menu-inline .ant-menu-item-selected::before{
  content: " ";
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 10px;
  border-left: 0;
}
.commoditySelect .commodity-lately-item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 500px;
}
.commoditySelect .commodity-lately-item-body{
  display: flex;
  padding: 5px;
  background: rgb(250,250,250);
  width: 100%;
  margin-left: 10px;
}
.commoditySelect .commodity-lately-item-body .commodity-lately-body-center{
  margin-left: 10px;
}
.commoditySelect .commodity-lately-body-center div:nth-child(2){
  background: rgb(235,241,250);
  color: rgb(44,114,254);
  display: inline-block;
  padding: 0 5px;
  border: 1px solid rgb(194,214,251);
}
.commoditySelect .commodity-lately-body-center div:nth-child(3){
  color: #999;
  font-size: 12px;
}
.addCommodity .ant-layout-sider{
  background: #fff;
}
.addCommodity .ant-empty{
  min-height: 400px;
}
.addCommodity .ant-empty-image {
  min-height: 210px;
}
.addCommodity-footer-select{
  border-bottom: 1px solid rgb(241,242,243);
  padding: 10px 20px;
}
.addCommodity-footer-select + div{
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
.addCommodity .ant-drawer-footer{
  padding: 0;
}
.addCommodity .addCommodity-distinguish{
  margin-top: 20px;
}
.addCommodity .addCommodity-distinguish button{
  margin-right: 20px;
}
.home-live-modal .ant-alert-warning{
  background-color: #fff;
  border: 0;
}

/* 直播推广 */
/* .LiveExtension {
  padding: 10px;
} */
.LiveExtension .LiveExtension-header{
  margin-top: 16px;
  background-color: rgb(242, 242, 242);
  padding: 0px 12px;
  border-radius: 8px;
  overflow: hidden;
}
.LiveExtension .LiveExtension-header-top{
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
}
.LiveExtension .LiveExtension-header-top button{
  background: transparent;
  margin-left: 12px;
}
.LiveExtension .LiveExtension-header-top-jian{
  background: #FFF3E0;
  color: #D48D0E;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  width: 44px;
  text-align: center;
  margin: 0 10px;
}
.LiveExtension .LiveExtension-header-footer{
  border-top: 1px solid #e6e6e6;
  padding: 16px 0;
}
.LiveExtension .anticon-question-circle{
  color: rgb(214, 214, 214);
  font-size: 14px;
}
.LiveExtension .LiveExtension-target-item{
  box-sizing: border-box;
  border-radius: 3px;
  background: #F2F2F2;
  box-shadow: 0 0 2px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 18px 0 0 18px;
  width: 200px;
  height: 70px;
}
.LiveExtension .targetAction{
  background-color: #fff;
  border: 1px solid #6647FF;
}
.LiveExtension .LiveExtension-target-item:hover{
  background-color: #F5EEFF;

}
.LiveExtension .LiveExtension-target-item p{
  line-height: 17px;
  font-size: 12px;
  color: rgba(0,0,0,0.4);
}
.LiveExtension .LiveExtension-target-item img{
  margin-right: 10px;
}
.LiveExtension .LiveExtension-target-body{
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(3, 200px);
  grid-template-rows: 72px;
}
.LiveExtension-header-body-right {
  width: 693px;
  padding-top: 36px;
}
.LiveExtension-header-body-right strong {
  margin: 20px 0 10px;
  display: block;
  font-size: 14px;
}
.LiveExtension .ant-radio-button-wrapper{
  background: #F2F2F2;
}
.LiveExtension .ant-radio-button-wrapper::before{
  background: #F2F2F2;
}
.LiveExtension .ant-radio-button-wrapper:hover{
  color: #6647FF;
}
.LiveExtension .ant-radio-button-wrapper-checked{
  border-color: #6647FF !important;
  border-left-color: #6647FF !important;
  color: #6647FF;
  background: #fff;
}
.LiveExtension .ant-radio-button-wrapper-checked::before{
  background: #6647FF;
}
.LiveExtension .ant-radio-button-wrapper{
  border: 1px solid #F2F2F2;
}
.LiveExtension .budget-border{
  width: 193px;
  background-color: #F0F0F0;
  border-color: #F0F0F0;
  margin-top: 20px;
}
.LiveExtension .budget-border .ant-input{
  background-color: #F0F0F0;
}
.LiveExtension .budget-border:focus{
  border-color: #6647ff;
  box-shadow: 0 0 0 2px rgb(102 71 255 / 20%);
  background-color: #F0F0F0;
}
.LiveExtension .budget-border:hover{
  border-color: #6647ff;
  box-shadow: 0 0 0 2px rgb(102 71 255 / 20%);
  background-color: #F0F0F0;
}
.LiveExtension-time{
  font-size: 12px;
}
.LiveExtension-clause{
  padding: 30px 0;
}
.LiveExtension-tip{
  cursor: pointer;
  display: inline-block;
  padding: 0 12px;
  border-radius: 14px;
  height: 28px;
  background: #f2f2f2;
  line-height: 28px;
  font-size: 14px;
  color: #333;
  margin: 0 auto;
}
.LiveExtension .ant-drawer-body{
  padding: 20px 0;
}
.LiveExtension-footer{
  text-align: right;
  padding: 30px 20px 0;
  border-top: 1px solid #edeef0;
  margin-top: 20px;
}
.LiveExtension-estimate{
  width: 220px;
  height: 160px;
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid #e3e3e4;
  border-radius: 4px;
  margin-top: 50px;
}
.LiveExtension-estimate div{
  font-size: 16px;
  font-weight: 700;
}
.LiveExtension-estimate strong{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  display: inline-block;
}
.LiveExtension-estimate p{
  line-height: 20px;
  font-size: 12px;
  color: #999;
}
.LiveExtension-header-body{
  display: flex;
  justify-content: space-between;
}
.LiveExtension-douyin{
  width: 222px;
  height: 395px;
  background: url("@assets/images/homeLive/LiveExtension-douyin.png") no-repeat;
  background-position: 100% 100%;
  background-size: 100%;
  margin-top: 70px;
  position: relative;
}
.LiveExtension-douyin .ant-avatar{
  position: absolute;
  bottom: 50px;
  left: 15px;
}
.LiveExtension-douyin .LiveExtension-douyin-title{
  position: absolute;
  bottom: 61px;
  left: 54px;
  color: #fff;
}

/* 分享裂变券 */
.ShareCoupons .ant-alert-icon{
  margin-right: 8px;
  margin-top: -21px;
  color: #6647ff;
}
.ShareCoupons .ant-alert{
  background-color: rgba(102,71,255,.08);
  border-color: rgba(102,71,255,.24);
  font-size: 12px;
}
.ShareCoupons-form{
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #edeef0;
  justify-content: space-between;
}
/* .ShareCoupons-form-right{
  width: 600px;
} */
.ShareCoupons-form-left{
  width: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-left: 1px solid #edeef0;
  padding-left: 24px;
}
.ShareCoupons-linkButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.ShareCoupons-linkButton .ant-btn-dangerous{
  color: #6647ff;
  background: #fff;
  border-color: #6647ff;
  margin-left: 20px;
}
.ShareCoupons-emptyText{
  background-image: url("@assets/images/homeLive/share-coupons-empty.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 224px;
  margin-bottom: 16px;
  position: relative;
}
.ShareCoupons-emptyText span{
  color: #898b8f;
  line-height: 20px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.ShareCoupons .ant-typography{
  margin-bottom: 0;
}
.RedEnvelopes-emptyText{
  background-image: url("@assets/images/homeLive/commodity-empty.png");
}
.setSeckill .ant-input-number-handler-wrap{
  display: none;
}
.live-commodity-item-seckill{
  display: inline-block;
}
.live-commodity-item-seckill img{
  vertical-align: middle;
}
.live-commodity-item-seckill span{
  background-color: rgb(255,225,223);
  display: inline-block;
  padding: 1px 3px;
  border-radius: 3px;
  margin-right: 10px;
  color: #f66;
}
.live-commodity-item-seckill strong{
  background-color: #fff;
  padding: 1px 3px;
  font-weight: normal;
}
.setSeckill-table .ant-table-thead th{
  white-space: nowrap;
}
.setSeckill-table .table_err{
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -18px;
  left: 0;
}
.table_err_input{
  border: 1px solid red;
}
/* .setSeckill-table-body .ant-input{
  font-size: 10px;
} */
.setSeckill-table-body .ant-input::placeholder{
  font-size: 14px;
}
.live-commodity .live-commodity-body{
  height: calc(100% - 98px);
  overflow: auto;
}


.ant-modal-header{
  border: 0;
}
.ant-modal-footer{
  border: 0;
}
.ant-input:hover{
  border-color: #6647ff;
}
.ant-input:focus, .ant-input-focused{
  border-color: #6647ff;
  box-shadow: 0px 0px -1px 1px #6647ff;
}
