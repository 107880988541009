.live-set{
  margin-left: 20px;
  background: #fff;
  height: 100%;
}
.live-set .site-page-header {
  border: 1px solid rgb(235, 237, 240);
}
.live-set .ant-form{
  margin-top: 20px;
}
.live-set .ant-radio-checked .ant-radio-inner{
  border-color: #6647ff;
  
}
.live-set .ant-radio-inner::after{
  background-color: #6647ff;
}