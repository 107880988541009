.withdrawalFunds .ant-form{
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
}
.withdrawalFunds .title-tips{
  background: #f3f4f6;
  padding: 10px 20px;
  border-radius: 6px;
}
.withdrawalFunds .allMoney .ant-form-text{
  display: block;
}
.withdrawalFunds .allMoney .ant-form-item-label > label{
  align-items: inherit;
}
.withdrawalFunds .ant-form-item-label > label{
  min-width: 80px;
  text-align: right;
  margin-right: 10px;
  justify-content: flex-end;
}
.withdrawalFunds #withdrawalFunds-form .ant-input,
.withdrawalFunds #withdrawalFunds-form .ant-input-affix-wrapper{
  border-radius: 4px;
}