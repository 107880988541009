.commodityManagement{
  margin-left: 20px;
}
.commodityManagement .commodityManagement-header{
  background: #fff;
  padding: 20px;
}
.commodityManagement-header-title{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #edeef0;
  padding-bottom: 20px;
}
.commodityManagement-header-title div{
  font-size: 16px;
}
.commodityManagement-header-title span{
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.commodityManagement-header-title span:hover{
  color: #6647ff;
}
.commodityManagement-header-form{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.commodityManagement-header-form-right span{
  margin-right: 20px;
}
.commodityManagement-header-form .ant-select{
  margin-right: 20px;
}
.commodityManagement-table{
  background: #fff;
  margin-top: 20px;
  padding: 20px;
}
.commodityManagement .commodityManagement-table-header{
  display: flex;
  justify-content: space-between;
}
.commodityManagement-table-header strong {
  font-size: 16px;
  margin-right: 20px;
}
.commodityManagement-table-header-right span {
  font-size: 12px;
  color: #85878a;
}
.commodityManagement-table-header div:nth-child(2) button{
  margin-left: 10px;
  background: #fff;
}
.commodityManagement-table-header div:nth-child(2) .ant-btn-primary{
  background: #6647ff;
}
.commodityManagement .ant-table{
  margin-top: 20px;
}
.commodityManagement .ant-table-cell{
  background: #fff;
}
.commodityManagement-table .ant-btn-link{
  color: #6647ff;
}
.commodityManagement-table .ant-btn-link:hover{
  color: #6647ff;
}
.commodityManagement-table-name{
  display: flex;
  width: 250px;
}
.commodityManagement-table-name-center{
  margin-left: 10px;
}
.commodityManagement-table-name-center .ant-typography{
  margin-bottom: 5px;
}
/* .commodityManagement-table-name-center div{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
} */
.commodityManagement-table-name-center div:nth-child(3){
  color: rgb(25, 102, 255);
  border: 1px solid rgba(25, 102, 255, 0.24);
  background-color: rgba(25, 102, 255, 0.06);
  margin: 0px 8px 0px 0px;
  width: 70px;
  font-size: 12px;
}
.addCommodity-table .commodityManagement-table-name-center div:nth-child(2){
  color: rgb(25, 102, 255);
  border: 1px solid rgba(25, 102, 255, 0.24);
  background-color: rgba(25, 102, 255, 0.06);
  margin: 0px 8px 0px 0px;
  width: 70px;
  font-size: 12px;
}
.displayWindow-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.displayWindow-header .displayWindow-header-lable{
  margin-right: 10px;
}
.displayWindow-header .ant-input{
  margin-right: 10px;
}
.addCommodity-table .addCommodity-footer{
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
}
.addCommodity-table .addCommodity-footer-right span{
  margin-right: 20px;
}
.commodityManagement-not-live{
  background: #f5f7fa;
  color: #707173;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 8px;
  margin-right: 20px;
}
.addCommodity-footer-select{
  border-bottom: 1px solid rgb(241,242,243);
  padding: 10px 20px;
  display: block;
}
.addCommodity-footer-select + div{
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
.home-maidian{
  color: #6647ff;
  cursor: pointer;
}
.commodityManagement .ant-typography{
  margin-bottom: 0px;
}