.RedEnvelopes{
  min-height: 600px;
  /* background-color: #fff; */
  margin-left: 15px;
}
.RedEnvelopes .RedEnvelopes-title,
.RedEnvelopes .create-record{
  padding: 30px;
  background: #fff;
  margin-bottom: 15px;
}

.RedEnvelopes .red-sum{
  padding: 10px 20px;
  border-radius: 6px;
  background: #f9f9fa;
  margin-top: 30px;
}
.RedEnvelopes .create-record .money-details{
  margin-top: 20px;
  background: #f9f9fa;
  border-radius: 6px;
  padding: 20px 0;
  margin-bottom: 25px;
}
.RedEnvelopes .create-record .money-details >div{
  width: 25%;
  padding-left: 60px;
}
.RedEnvelopes .create-record .money-details >div +div{
  border-left: 1px solid #f1f2f4;
}
.RedEnvelopes .red-sum .available p:nth-child(3){
  color: #c7bdff;
  font-size: 14px;
}
.RedEnvelopes .red-tables{
  border-top: 1px solid #ccc;
  padding: 25px 0;
}
.RedEnvelopes .red-tables .ant-picker{
  width: 100%;
}
.RedEnvelopes .red-tables .ant-col{
  padding-right: 1%;
}
.RedEnvelopes .red-tables .ant-col:nth-child(3) .ant-form-item,
.RedEnvelopes .red-tables .ant-col:nth-child(4) .ant-form-item{
  margin-bottom: 0;
}
.RedEnvelopes .red-tables .ant-form{
  border-right: 1px solid #ccc;
}
.RedEnvelopes .launch_info .yu{
  background-color: #e2effb;
  color: #6a87c6;
  padding: 0 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.RedEnvelopes .launch_info .qiang{
  background-color: #ffefd1;
  color: #e1a54d;
  padding: 0 4px;
  margin-right: 5px;
}
.RedEnvelopesDrawer .ant-form{
  padding-left: 100px;
  margin-top: 20px;
}
.RedEnvelopesDrawer .title-tips{
  background: #f3f4f6;
  padding: 10px 20px;
  border-radius: 6px;
}
.RedEnvelopesDrawer .ant-input-affix-wrapper{
  width: 25%;
}
.RedEnvelopesDrawer .allMoney .ant-form-text{
  display: block;
}
.RedEnvelopesDrawer .allMoney .ant-form-item-label > label{
  align-items: inherit;
}
.RedEnvelopesDrawer .discount .ant-input-affix-wrapper{
  width: 20%;
  margin: 0 4px;
}
.RedEnvelopesDrawer .ant-input-affix-wrapper{
  width: 25%;
}
.RedEnvelopesDrawer .allMoney .ant-form-text{
  display: block;
}
.RedEnvelopesDrawer .allMoney .ant-form-item-label > label{
  align-items: inherit;
}
.RedEnvelopesDrawer .discount .custom-inp{
  width: 60%;
}
.RedEnvelopesDrawer .discount .ant-select{
  margin: 0 4px;
}

.RedEnvelopesDrawer .setCoupons{
  background-color: #f9f9fa;
  padding: 20px 10px 0;
  border-radius: 6px;
  width:50%;
  margin-left: 100px;
  color: #949799;
  line-height: 32px;
  margin-bottom: 24px;
}
.RedEnvelopesDrawer .wh-span{
  display: inline-block;
  width: 80px;
}
.RedEnvelopesDrawer .product-box{
  padding-left: 100px;
}

.RedEnvelopesDrawer .product-box .product-list{
  background: #f9f9fa;
  padding: 20px;
}

.addproduct-modal{
  width: 800px !important;
}
.addproduct-modal .ant-modal-header{
  background: #f3f4f6;
}

.RedEnvelopes-emptyText{
  background-image: url("@assets/images/homeLive/commodity-empty.png");
}
.RedEnvelopesDrawer .discount .ant-input-affix-wrapper input::-webkit-outer-spin-button,
.RedEnvelopesDrawer .discount .ant-input-affix-wrapper input::-webkit-inner-spin-button {
-webkit-appearance: none;

}
input[type="number"]{
-moz-appearance: textfield;
}

.RedEnvelopesDrawer .ant-form-item-label{
  width: 100px;
  text-align: right;
}
.LaunchSetDrawer .red_info,
.LaunchSetDrawer .ant-form{
  padding-left: 50px;
}
.LaunchSetDrawer .discount .custom-inp{
  width: 30%;
}
.LaunchSetDrawer .info{
  font-size: 18px;
  font-weight: bold;
  margin: 30px 0;
}
.LaunchSetDrawer .red_title{
  display:inline-block;
  min-width: 100px;
  text-align: right;
  margin-right: 15px;
  color: #757678;
  font-size: 16px;
  margin-bottom: 10px;
}
