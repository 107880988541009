.anchorTicket{
  min-height: 600px;
  /* background-color: #fff; */
  margin-left: 15px;
}
.anchorTicket .anchorTicket-title,
.anchorTicket .create-record{
  padding: 30px;
  background: #fff;
  margin-bottom: 15px;
}

.anchorTicket .red-sum{
  padding: 10px 20px;
  border-radius: 6px;
  background: #f9f9fa;
  margin-top: 30px;
}
.anchorTicket .create-record .money-details{
  margin-top: 20px;
  background: #f9f9fa;
  border-radius: 6px;
  padding: 20px 0;
  margin-bottom: 25px;
}
.anchorTicket .create-record .money-details >div{
  width: 25%;
  padding-left: 60px;
}
.anchorTicket .create-record .money-details >div +div{
  border-left: 1px solid #f1f2f4;
}
.anchorTicket .red-sum .available p:nth-child(3){
  color: #c7bdff;
  font-size: 14px;
}
.anchorTicket .red-tables{
  border-top: 1px solid #ccc;
  padding: 25px 0;
}
.anchorTicket .red-tables .ant-picker{
  width: 100%;
}
.anchorTicket .red-tables .ant-col{
  padding-right: 1%;
}
.anchorTicket .red-tables .ant-col:nth-child(5) .ant-form-item{
  margin-bottom: 0;
}
.anchorTicket .red-tables .ant-form{
  border-right: 1px solid #ccc;
}
.anchorTicket .text-center{
  width: 10%;
}
.anchorTicket .ant-form-item-label > label{
  width: 90px;
  justify-content: flex-end;
}
.AddAnchorTicketDrawer .ant-form{
  padding-left: 20px;
  margin-top: 20px;
}
.AddAnchorTicketDrawer .title-tips{
  background: #f3f4f6;
  padding: 10px 20px;
  border-radius: 6px;
}
.AddAnchorTicketDrawer .discount{
  align-items: center;
}
.AddAnchorTicketDrawer .discount{
  width: 95%;
  margin: 0 4px;
  margin-bottom: 24px;
}
.AddAnchorTicketDrawer .discount .custom-inp > span{
  height: 32px;
  line-height: 32px;
}
.AddAnchorTicketDrawer .discount .custom-inp .ant-input-affix-wrapper{
  width: 100px;
}
.AddAnchorTicketDrawer .discount .ant-form-item{
  width: 20%;
}
.AddAnchorTicketDrawer .allMoney .ant-form-text{
  display: block;
}
.AddAnchorTicketDrawer .allMoney .ant-form-item-label > label{
  align-items: inherit;
}
.AddAnchorTicketDrawer .discount .custom-inp{
  display: flex;
  background-color: #f9f9fa;
  padding: 20px 10px;
  border-radius: 6px;
  width: 100%;
}
.AddAnchorTicketDrawer .discount .ant-select{
  margin: 0 4px;
}

.AddAnchorTicketDrawer .setCoupons .custom-inp{
  width:70%;
  margin-left: 140px;
  background-color: #f9f9fa;
  padding: 20px 10px;
}
.AddAnchorTicketDrawer .setCoupons .custom-inp .ant-form-item{
  width: 30%;
  margin-bottom: 0;
  margin: 0 6px;
}
.AddAnchorTicketDrawer .setCoupons .custom-inp .times{
  width: 100%;
}
.AddAnchorTicketDrawer .product-box{
  /* padding-left: 100px; */
}

.AddAnchorTicketDrawer .product-box .product-list{
  background: #f9f9fa;
  padding: 20px;
  margin-left: 140px;
}

.addproduct-modal{
  width: 800px !important;
}
.addproduct-modal .ant-modal-header{
  background: #f3f4f6;
}
.addproduct-modal .ant-checkbox+span {
  display: none;
}
.addproduct-modal .invitation{
  color: #6647ff;
}
.RedEnvelopes-emptyText{
  background-image: url("@assets/images/homeLive/commodity-empty.png");
}
.AddAnchorTicketDrawer .discount .ant-input-affix-wrapper input::-webkit-outer-spin-button,
.AddAnchorTicketDrawer .discount .ant-input-affix-wrapper input::-webkit-inner-spin-button {
-webkit-appearance: none;

}
input[type="number"]{
-moz-appearance: textfield;
}

.addproduct .ant-table-tbody > tr > td:nth-child(2){
  width: 300px;
}
.AddAnchorTicketDrawer .ant-form-item-label > label{
  width: 130px;
  justify-content: flex-end;
  margin-right: 10px;
}
.AddAnchorTicketDrawer .limitedCollarNum .ant-form-item-control-input-content,
.AddAnchorTicketDrawer .couponName .ant-form-item-control-input-content{
  flex: inherit;
  width: 200px;
}
.checkedListErr{
  color: #ff4d4f;
  font-size: 14px;
  margin-left: 140px;
}