.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 200px;
  padding: 48px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}
.Dy-Drawer{
  /* top: 64px; */
  bottom: 0;
  height: auto;
  /* z-index: 999999; */
}

/* 修改全局组件样式 */

.Dy-Drawer .ant-radio-button-wrapper-checked{
  color: #6647ff;
  border-color: #6647ff;
}
.Dy-Drawer .ant-radio-button-wrapper-checked::before{
  background-color: #6647ff;
}

.Dy-Drawer .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
  border-color: #6647ff;
}
.Dy-Drawer .ant-radio-button-wrapper:hover {
  position: relative;
  color: #6647ff;
}

.Dy-Drawer .ant-picker:hover, .ant-picker-focused {
  border-color: #6647ff;
  border-right-width: 1px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: 1px solid #6647ff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background: #6647ff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background: #6647ff;
}
.ant-picker-range .ant-picker-active-bar{
  background: #6647ff;
}

.ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open{
  transition: transform 1.5s cubic-bezier(0.7, 0.3, 0.1, 1);
}