.SendCouponsModal .ant-table-cell{
  background-color: #fff;
}
.SendCouponsModal .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  width: 0;
}
.SendCouponsModal .ant-modal-header{
  background-color: #f3f4f6;
}
.SendCouponsModal .SendCouponsModal-modal-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SendCouponsModal .ant-modal-footer{
  padding: 10px 24px;
}
.SendCouponsModal .SendCouponsModal-search{
  display: flex;
}
.SendCouponsModal .SendCouponsModal-search button{
  margin-left: 20px;
}