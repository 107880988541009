.fundDetails{
  min-height: 600px;
  /* background-color: #fff; */
  margin-left: 15px;
}
.fundDetails .fundDetails-title,
.fundDetails .create-record{
  padding: 15px 30px;
  background: #fff;
  margin-bottom: 15px;
}
.fundDetails .create-record .money-details{
  margin-top: 20px;
  background: #f9f9fa;
  border-radius: 6px;
  padding: 20px 0;
  margin-bottom: 25px;
}
.fundDetails .create-record .money-details >div{
  width: 25%;
  padding-left: 60px;
}
.fundDetails .create-record .money-details >div +div{
  border-left: 1px solid #f1f2f4;
}
.fundDetails .red-sum .available p:nth-child(3){
  color: #c7bdff;
  font-size: 14px;
}
.fundDetails .red-tables{
  /* border-top: 1px solid #ccc; */
  padding: 25px 0;
}
.fundDetails .red-tables .ant-picker{
  width: 100%;
}
.fundDetails .red-tables .ant-col{
  padding-right: 1%;
}
.fundDetails .red-tables .ant-col:nth-child(5) .ant-form-item{
  margin-bottom: 0;
}
.fundDetails .text-center{
  width: 100%;
}

.RedEnvelopes-emptyText{
  background-image: url("@assets/images/homeLive/commodity-empty.png");
}