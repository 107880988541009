
.ant-tabs-ink-bar{
  background: #6647ff;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #6647ff;
  
}
.ant-tabs-tab:hover{
  color: #6647ff;
}
.ant-btn-primary{
  background: #6647ff;
  border-color: #6647ff;
}
.ant-btn-primary:hover, .ant-btn-primary:focus{
  background: #6647ff;
  border-color: #6647ff;
}
.LiveSendCouponsTable-header{
  text-align: right;
  color: #6647ff;
}
.LiveSendCouponsTable-header .ant-btn{
  margin-left: 20px;
}