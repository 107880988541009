.DataLive{
  margin-left: 20px;
}
.DataLive-header{
  background: #fff;
  border-radius: 4px;
  margin-top: 0;
  padding: 48px 60px;
  text-align: center;
}
.DataLive-header .ant-col-4{
  flex: 1;
  margin: 0 8px;
  padding: 4px 8px 8px 24px;
  position: relative;
}
.DataLive-header .ant-col-4 div{
  color: #19191a;
  font-size: 12px;
  line-height: 20px;
}
.DataLive-header .ant-col-4 p{
  color: #19191a;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 8px;
}
.DataLive-header .ant-col-4:nth-child(3){
  border-left: 1px solid #edeef0;
}
.DataLive-table{
  background: #fff;
  margin-top: 20px;
  padding: 20px;
}
.DataLive-table h3{
  padding: 10px 0;
}
.DataLive-table-basics{
  display: flex;
}
.DataLive-table-basics img{
  margin-right: 10px;
}
.DataLive-table-basics div{
  align-items: center;
  color: #19191a;
  height: 20px;
  line-height: 16px;
}
.DataLive-table-basics p{
  color: #717273;
  line-height: 16px;
  margin-top: 4px;
}
.DataLive-table-operation span{
 cursor: pointer;
 color: #6647ff;
 padding: 0px 10px;
 display: inline-block;
}
.DataLive-table .ant-table-cell,.DataLiveDetail .ant-table-cell{
  background: #fff;
}


/* 直播数据==详情 */
.DataLiveDetail{
  margin-left: 20px;
}
.DataLiveDetail-header{
  background: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 24px;
  position: relative;
}
.DataLiveDetail-header-right{
  background: #fff;
}
.DataLiveDetail-header-right div{
  align-items: center;
  color: #12141a;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}
.DataLiveDetail-header-right p{
  color: #85878a;
  line-height: 16px;
  font-size: 14px;
  font-weight: normal;
}
.DataLiveDetail-header-right img{
 width: 72px;
 height: 72px;
}
.DataLiveDetail-header-left{
  text-align: right;
}
.DataLiveDetail-header-left p{
  margin-bottom: 10px;
}
.DataLiveDetail .ant-alert-warning{
  background: rgba(102,71,255,.08);
  border: 1px solid rgba(102,71,255,.24);
  margin: 20px 0;
}
.DataLiveDetail .ant-alert-warning .ant-alert-icon{
  color: #6647ff;
}
.DataLiveDetail-board{
  background: #fff;
  padding: 20px;
}
.DataLiveDetail-board-body{
  display: flex;
  flex-wrap: wrap;
}
.DataLiveDetail-item{
  display: flex;
}
.DataLiveDetail-item li{
  flex-grow: 1;
  padding-left: 0;
  width: 115px;
}
.DataLiveDetail-item li img{
  width: 32px;
  height: 32px;
  margin: auto;
}
.DataLiveDetail-item li:first-child{
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  text-align: center;
  margin-right: 16px;
  padding: 16px 0;
  width: 56px;
}
.DataLiveDetail-item li:first-child div{
  color: #12141a;
}
.DataLiveDetail-item li span{
  background-color: #f3f4f6;
  border-radius: 2px;
  color: #55585c;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
  padding: 0 4px;
}
.DataLiveDetail-item li div{
  color: rgb(133, 135, 138);
  line-height: 16px;
  font-size: 12px;
}
.DataLiveDetail-item li p{
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #12141a;
}
.DataLiveDetail-trend{
  margin: 20px 0;
  background: #fff;
  padding: 20px;
}
.DataLiveDetail h3{
  color: #12141a;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
.DataLiveDetail h3 span{
  color: #85878a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 20px;
}
.DataLiveDetail-trend .ant-checkbox-wrapper{
  margin-left: 40px;
  width: 200px;
}
.DataLiveDetail-trend-realTime{
  height: 320px;
}
.DataLiveDetail .ant-tabs-nav {
  margin-bottom: 0;
  background: #fff;
  padding: 0 20px;
}
/* .DataLiveDetail-tabs{
  background: #fff;
} */
.DataLiveDetail-flow{
  margin: 20px 0;
}
.DataLiveDetail-flow-analysis{
  background: #fff;
  padding: 20px;
}
.DataLiveDetail-flow-progress{
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.DataLiveDetail-flow-progress .ant-progress-line{
  width: 382px;
  margin-left: 20px;
}
.DataLiveDetail-conversion{
  margin: 20px 0;
  background: #fff;
  padding: 20px;
}
.DataLiveDetail-conversion-body{
  width: 1088px;
  height: 550px;
  text-align: center;
}
.DataLiveDetail-conversion-body img {
  margin-top: 100px;
}
.DataLiveDetail-conversion-data{
  display: flex;
  border: 1px solid #edeef0;
  padding: 20px 0;
}
.DataLiveDetail-conversion-data li{
  width: 25%;
  padding: 0 20px;
  border-right: 1px solid #edeef0;
}
.DataLiveDetail-conversion-data li:nth-child(5){
  border-right: 0;
}
.DataLiveDetail-conversion-data div{
  color: rgb(133, 135, 138);
  line-height: 16px;
  font-size: 12px;
}
.DataLiveDetail-conversion-data p{
  color: #12141a;
  font-size: 24px;
  font-weight: 500;
  margin-top: 4px;
}
.DataLiveDetail-empty{
  background-image: url("@assets/images/homeLive/share-coupons-empty.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 324px;
  margin-bottom: 16px;
  position: relative;
}
.DataLiveDetail-empty span{
  color: #898b8f;
  line-height: 20px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.DataLiveDetail-portrait{
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}
.DataLiveDetail-portrait-item .ant-empty{
  width: 363px;
  height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.DataLiveDetail-portrait-item .ant-empty .ant-empty-image {
  width: 240px;
  height: 168px;
}
.DataLiveDetail-portrait-item h4{
  color: #12141a;
  font-size: 14px;
}
.DataLiveDetail-ranking{
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
}
.DataLiveDetail-portrait-item .DataLiveDetail-flow-progress{
  margin-bottom: 20px;
}
.DataLiveDetail-table-basics{
  display: flex;
  align-items: center;
}
.DataLiveDetail-table-basics div{
  margin-left: 10px;
  margin-bottom: 0;
}
.DataLiveDetail-trend .ant-radio-group{
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.DataLiveDetail-conversion-cost ul{
  display: flex;
  padding-left: 0;
}
.DataLiveDetail-conversion-cost ul li{
  width: 20%;
  text-align: center;
}
.DataLiveDetail-conversion-cost ul li span:nth-child(1){
  color: rgb(133,144,158);
  font-weight: normal;
}
.DataLiveDetail-conversion-cost ul li .anticon{
  color: rgb(133,144,158);
  font-weight: normal;
}
.DataLiveDetail-conversion-cost ul li span{
  font-size: 14px;
  font-weight: 700;
}