/* 全局样式 */
::selection {
  color: #fff;
  background-color: #6647ff;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #6647ff;
}
.ant-radio-inner::after{
  background-color: #6647ff;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6647ff;
  border-color: #6647ff;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #6647ff;
}
body .ant-btn:not(.ant-btn-link):hover {
  background: #fff;
  border-color: #9580ff;
  color: #9580ff;
}
body  .ant-btn.ant-btn-primary:hover {
  background: #9580ff;
  border-color: transparent;
  color: #fff;
}
body .ant-btn.ant-btn-dashed {
  background: #fff;
  border: 1px solid #6647ff;
  box-shadow: none;
  color: #6647ff;
}
body .ant-btn.ant-btn-dashed:hover {
  background: #fff;
  border-color: #9580ff;
  color: #9580ff;
}
body .ant-input-affix-wrapper-focused, body .ant-input-affix-wrapper:focus, body .ant-input-affix-wrapper:hover, body .ant-input-focused, body .ant-input:focus, body .ant-input:hover{
  border-color: #6647ff;
  box-shadow: none;
}
body .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  border-color: #6647ff;
}

body .ant-picker-focused, body .ant-picker:hover {
  border-color: #6647ff;
}
body .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: #6647ff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #f4f2ff;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #6647ff;
  box-shadow: none;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after{
  background-color: #6647ff;
}
.ant-table-thead > tr > th{
  background: #fff;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  width: 0;
}
.ant-pagination-item-active{
  border-color: #6647ff;
}
.ant-pagination-item-active a {
  color: #6647ff;
}