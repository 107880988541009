.sidenav{
  flex: 0 0 184px!important;
  max-width: 184px!important;
  min-width: 184px!important;
  position: fixed;
  top: 124px;
  width: 184px!important;
  z-index: 999;
  height: calc(100vh - 124px);
  overflow: auto;
}
.sidenav246{
  flex: 0 0 246px!important;
  max-width: 246px!important;
  min-width: 246px!important;
  position: fixed;
  top: 100px;
  width: 246px!important;
  z-index: 999;
  height: calc(100vh - 124px);
  overflow: auto;
  padding-top: 16px;
  background-color: #fff;
}
.sidenav::-webkit-scrollbar {
  width: 0px;
}
/* .sidenav::-webkit-scrollbar-thumb {
  background-color:#dddddd;
  background-clip:padding-box;
  min-height:28px;
} */
.sidenav .ant-menu{
  font-size: 12px;
  margin-top: -.1px;
  padding-top: .1px;
}
.ant-menu-inline .ant-menu-item::after{
  border-right: 0;
}
.ant-menu-inline .ant-menu-item-selected::before{
  content: " ";
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 10px;
  border-left: 2px solid #6647ff;
}
.ant-menu-item:hover{
  color: #6647ff;
}
.ant-menu-inline .ant-menu-item-selected{
  color: #6647ff;
}
.ant-menu-submenu-selected{
  color: #6647ff;
}
.ant-menu-sub.ant-menu-inline{
  background: #fff;
}
/* .sidenav.sidenav246 .ant-menu-submenu{
  padding: 2px;
} */
.sidenav.sidenav246 .ant-menu-submenu .ant-menu{
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px 0px 50px;
}
.sidenav.sidenav246 .ant-menu-submenu .ant-menu .ant-menu-item{
  padding: 0 !important;
  width: 45%;
  justify-content: flex-start;
  font-size: 12px;
}
.sidenav.sidenav246  .ant-menu-submenu:hover{
  background-color: rgba(25,102,255,.05);
}
.sidenav.sidenav246  .ant-menu-submenu:hover .ant-menu-sub.ant-menu-inline{
  background-color: transparent;
}